<template>
  <vx-card title="Product B2B">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        />
      </div>
    </div>
    <div class="vx-input-group flex">
      <vs-button
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-upload"
        @click="handleImport()"
        >Import</vs-button
      >
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-download"
        @click="ExportForm()"
        >Export</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <data-table
          @selected-items="handleSelectedItems"
          :territoryID="this.selectedTerritory ? this.selectedTerritory.id : 0"
          :baseUrl="this.baseUrl"
          :detail="this.detail"
          :draw="draw"
        ></data-table>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTable,
    Datepicker,
  },
  data() {
    return {
      uploadReady: true,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/product-btwob-rules",
      optionTerritory: [],
      selectedTerritory: null,
      draw: 0,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "product-back-to-back-create",
        // params: { id: id },
      });
    },
    handleImport() {
      this.$router.push({
        name: "product-b2b-import",
        // params: { id: id },
      });
    },
    handleSelectedItems(selectedItems) {
      console.log(selectedItems);
      this.selectedExport = selectedItems;
    },
    ExportForm() {
      const id = this.$route.params.id;
      var fileTitle = "PRODUCT_TEAM_EXPORT_";
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-btwob-rules/export", {
          id: parseInt(this.$route.params.id),
          responseType: "arraybuffer",
          params: {
            selectedExport: this.selectedExport.join(", "),
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
    handleOpen() {
      this.detail = true;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                // this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = null;
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
  },
  mounted() {
    this.getTerritory();
  },
  watch: {
    selectedTerritory() {
      this.draw++;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
